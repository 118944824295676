<template>
    <v-card class="card-shadow border-radius-xl">
        <div class="px-4 py-3 d-flex justify-space-between">
            <h6 class="mb-0 text-h6 font-weight-bold text-typo d-flex align-center">
                <v-icon v-if="icon" size="12" class="text-typo mr-2">{{ icon }}</v-icon>
                {{ label }}
            </h6>
            <div class="d-flex align-center">
                <slot></slot>
            </div>
        </div>
    </v-card>
</template>
<script>

export default {
    name: 'DetailFundAttribute',
    props: {
        label: String,
        icon: String,
        showCard: Boolean
    }
}
</script>
