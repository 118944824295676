<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row v-if="company">
        <v-col cols="12" class="z-index-2">
          <v-card class="bg-transparent mb-6 px-4 py-4 pl-0">
            <h1 class="text-h1 text-typo font-weight-bolder mb-0 d-flex align-center">
              <img v-if="company.logoUrl" :src="company.logo_url" class="my-0 me-5" style="max-width: 150px; max-height: 70px;">
              {{company.company_name}}
              <span v-if="company.rank" class="font-weight-bolder text-h3 text-typo mb-0">{{company.rank}}%</span>
              <span class="font-weight-bolder text-h5 text-success mb-0 ms-2">+12%</span>
            </h1>
          </v-card>

          <v-row>
            <v-col sm="12" md="4">
              <v-card class="card-shadow border-radius-xl">
                <div class="px-4 py-3 d-flex justify-space-between">
                  <h6 class="mb-0 text-h6 font-weight-bold text-typo">SFDR</h6>
                  <div>
<!--                    <v-icon>far fa-calendar-alt me-2</v-icon>-->
                    <small class="text-body">{{company.sfdr}}</small>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col sm="12" md="4">
              <v-card class="card-shadow border-radius-xl">
                <div class="px-4 py-3 d-flex justify-space-between">
                  <h6 class="mb-0 text-h6 font-weight-bold text-typo">Funds</h6>
                  <div>
                      <div v-for="fund in funds" :key="fund._id">
<!--                          todo: route to fund-->
                          <a>
                            <small class="text-body">{{fund.fund_name}} - <strong>{{fund.fund_isin}}</strong></small>
                          </a>
                      </div>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col sm="12" md="4">
              <v-card class="card-shadow border-radius-xl">
                <div class="px-4 py-3 d-flex justify-space-between">
                  <h6 class="mb-0 text-h6 font-weight-bold text-typo">Country</h6>
                  <div class="d-flex align-center">
                    <img v-if="company.countryCode" :src="'https://countryflagsapi.com/png/' + company.countryCode" height="15" class="me-2">
                    <small class="text-body">{{company.countryCode}}</small>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col v-for="t in trends" sm="4" cols="12">
                  <v-card class="border-radius-xl overflow-hidden card-shadow">
                    <div class="px-4 pb-0 pt-4">
                      <p class="text-sm mb-0 text-capitalize font-weight-bold text-body">{{t.name}}</p>
                      <h5 class="font-weight-bolder text-h5 text-typo mb-0">{{t.value}}% <span class="text-success text-sm font-weight-bolder">+{{t.diff}}%</span></h5>
                    </div>
                    <div>
                      <esg-line-chart :id="t.name" :datasets="t.datasets" :height="200"></esg-line-chart>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-for="check in checks" :key="check._id" sm="12" md="4">
              <v-card class="card-shadow border-radius-xl mb-5">
                <div class="px-4 pt-4">
                  <v-row>
                    <v-col md="6">
                      <h6 class="mb-0 text-h6 font-weight-bold text-typo">ESG Plausibility Check</h6>
                    </v-col>
                    <v-col md="6" class="text-end">
                      <v-icon>far fa-calendar-alt me-2</v-icon>
                      <small class="text-body">{{check.createdAtDay}}</small>
                    </v-col>
                  </v-row>
                </div>
                <div class="px-4 pb-4 pt-2">
                    <div v-for="(sector) in check.sectors">
                        <v-list-item :key="sector.name" class="px-0 py-4">
                            <span v-bind:style="{color: sector.color}" class="me-2 text-sm mt-n1">●</span>

                            <v-list-item-content class="py-0">
                                <div class="d-flex flex-column">
                                    <h6 class="mb-1 text-typo text-sm font-weight-bold">{{sector.name}}</h6>
                                </div>
                            </v-list-item-content>

                            <v-list-item-content class="py-0 text-end">
                                <div class="font-weight-bold text-typo">{{sector.resultInPercents}}%</div>
                                <div v-if="sector.diff" v-bind:class="{'text-success': sector.diff > 0, 'text-danger': sector.diff < 0}" class="text-sm font-weight-bold">
                                    <template v-if="sector.diff > 0">+</template>
                                    {{sector.diff}}%
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </div>
              </v-card>
            </v-col>

              <v-col v-for="check in checks" :key="'w_' + check._id" sm="12" md="4">
                  <v-card class="card-shadow border-radius-xl mb-5">
                      <div class="px-4 pt-4">
                          <v-row>
                              <v-col md="6">
                                  <h6 class="mb-0 text-h6 font-weight-bold text-red">
                                      <span class="text-red">{{check.warnings.length}}</span> Warnings
                                  </h6>
                              </v-col>
                              <v-col md="6" class="text-end text-red">
                                <strong class="text-typo ms-2">show</strong>
                              </v-col>
                          </v-row>
                      </div>
                  </v-card>
              </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <canvas id="grhelper"></canvas>
  </div>
</template>

<script>
import ESGLineChart from '@/views/Widgets/Charts/LineChart'
import {getChartGradientBySector, getTooltipPercentFormatted} from '@/util/charts'
import {Company} from '@/entities/Company'
import {CheckFactory} from '@/entities/CheckFactory'

export default {
  name: 'Company',
  components: {
    'esg-line-chart': ESGLineChart
  },

  data: function () {
    return {
      company: null,
      funds: [],
      trends: [],
      checks: []
    }
  },

  async created() {
    const {company} = await this.$store.getters.findOne('company', this.$route.params.id)
    this.company = new Company(company)

    const [funds, checks] = await Promise.all([
        await this.$store.getters.request(`company/${this.company._id}/fund`),
        await this.$store.getters.request(`company/${this.company._id}/check`),
    ])

    this.funds = funds.data.funds
    this.company.checks = checks.data.checks

    if (this.company.checks.length) {
        const countries = await CheckFactory.fetchCountries()
        this.checks = [await CheckFactory.createPlausibilityCheck(this.company, countries)]
    }

    const {data: {trends}} = await this.$store.getters.request(`company/${this.company._id}/trend`)
    this.company.setTrends(trends)
    this.generateTrends()
  },

  methods: {
    generateTrends() {
      this.trends = this.company.trends.map(trend => {
        return {
          name: trend.name,
          color: trend.color,
          value: trend.value,
          diff: trend.value,
          datasets: [
            {
                label: trend.name,
                tension: 0.4,
                borderWidth: 3,
                pointRadius: 0,
                borderColor: trend.color,
                backgroundColor: getChartGradientBySector(trend.name, document.getElementById('grhelper').getContext('2d')),
                fill: true,
                data: [trend.value],
                tooltip: getTooltipPercentFormatted(),
                maxBarThickness: 6,
            }
          ]
        }
      })
    }
  }
}
</script>
