<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col md="12" lg="10" class="z-index-2">
          <v-card class="bg-transparent mb-6 px-4 py-4 ps-0">
            <div class="d-flex align-center justify-space-between">
                <h2 class="text-h2 text-typo font-weight-bolder">Funds Management</h2>
                <div>
<!--                    <v-btn @click="refreshCache()" color="green" :disabled="cachePending"-->
<!--                           class="text-capitalize ms-auto btn-primary text-sm px-3 mb-5 text-decoration-none"-->
<!--                    >-->
<!--                        <span>Refresh cache</span>-->
<!--                    </v-btn>-->

                    <v-btn @click="showCreateForm()" color="green"
                           class="text-capitalize ms-auto btn-primary bg-gradient-primary text-sm px-3 mb-5 text-decoration-none"
                           style="margin-left: 1rem !important;"
                    >
                        <span>Add fund</span>
                    </v-btn>
                </div>

            </div>
            <p class="text-hypo mt-2">For more advanced database management, such as imports, etc., please use BOTX web console application</p>
          </v-card>

          <v-card>
            <v-text-field v-model="term" hide-details outlined color="rgba(0,0,0,.6)" light placeholder="Search in Funds"
                          class="font-size-input placeholder-lighter border border-radius-md mt-2">
            </v-text-field>
          </v-card>

          <v-card id="funds-grid" class="bg-transparent tabs">
            <v-row>
              <v-col cols="12" class="mt-5">
                <funds-grid :allow-create="true" ref="fundsGrid" :initial-funds="funds" :show-f-s-d-r="true" :item-header="'Fund'" :show-logo="false" ></funds-grid>
              </v-col>
            </v-row>
          </v-card>

          <div v-if="total > 0 && funds.length < total" class="text-center my-5">
            <v-btn @click="next()" color="green"
                   class="text-capitalize ms-auto btn-primary bg-gradient-primary text-sm px-3 mb-5 text-decoration-none"
                   style="margin-left: 2.625rem !important;"
            >
              <span>Load more</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FundsGrid from '@/views/Widgets/FundsGrid'
import {debounce} from '@/util/debounce'
import {FundScan} from '@/entities/FundScan'

export default {
  name: 'PortfolioFunds',
  components: {
    FundsGrid,
  },

  computed: {
    api() {
      return this.$store.state.api
    }
  },

  data: function () {
    return {
      funds: [],
      total: 0,
      term: null,
      tab: null,
      fund: null,
      company: null,
      showCreate: false,
      headers: null,
      page: 0,
      cachePending: false
    }
  },

  watch: {
    term: debounce(function () {
      // if (this.term.length > 1) {
          this.fetch({flush: true})
      // }
    }, 400)
  },

  async created() {
    this.fetch()
  },

  methods: {
    async fetch(options) {
      if (options?.flush) {
          this.funds = []
          this.page = 0
      }

      try {
        let filter = {}
        if (this.term?.length) {
            filter = {
                $or: [
                    {fund_name: {$regex: [this.term, 'i']}},
                    {fund_isin: {$regex: [this.term, 'i']}},
                ]
            }
        }

        const {data: {result: funds, total}} = await this.$store.state.api.get(`fund/scans?page=${this.page}&perPage=10&sort=fund_name&sortDir=asc&filter=${encodeURIComponent(JSON.stringify(filter))}`)
        this.funds = [...this.funds, ...funds.map(i => new FundScan(i))]
        this.total = total
      } catch (e) {
        console.error('Cannot fetch funds', e)
        this.$store.commit('setFunds', [])
      }
    },

    next() {
        // window.scrollTo(0, 0)
        ++this.page
        this.fetch()
    },

    showCreateForm() {
      this.$refs.fundsGrid.showCreateForm()
    },

    async refreshCache() {
        this.cachePending = true
        await this.$store.state.api.get(`cache/refresh`)
        this.cachePending = false
    }
  }
}
</script>
<style lang="scss"></style>
