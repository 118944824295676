<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col lg="4" md="6" cols="12" class="mb-6">
            <v-card class="py-4 px-4">
              <div>
                <h5 class="font-weight-bolder text-h5 text-typo mb-0">Company Information</h5>
                <v-row class="mt-2">
                  <v-col cols="12">
                    <label class="text-xs text-typo font-weight-bolder ms-1">Company Name</label>
                    <v-text-field
                      v-model="name"
                      hide-details
                      outlined
                      color="rgba(0,0,0,.6)"
                      light
                      placeholder="eg. AXA"
                      class="
                        font-size-input
                        placeholder-lighter
                        border border-radius-md
                        mt-2
                      "
                    >
                    </v-text-field>
                  </v-col>
<!--                  <v-col sm="6" cols="12">-->
<!--                    <label class="text-xs text-typo font-weight-bolder ms-1"-->
<!--                      >Weight</label-->
<!--                    >-->
<!--                    <v-text-field-->
<!--                      hide-details-->
<!--                      outlined-->
<!--                      color="rgba(0,0,0,.6)"-->
<!--                      light-->
<!--                      placeholder="eg. 42"-->
<!--                      class="-->
<!--                        font-size-input-->
<!--                        placeholder-lighter-->
<!--                        border border-radius-md-->
<!--                        mt-2-->
<!--                      "-->
<!--                    >-->
<!--                    </v-text-field>-->
<!--                  </v-col>-->
<!--                </v-row>-->

<!--                <v-row class="mt-2">-->
<!--                  <v-col sm="6" cols="12" class="mb-sm-0 mb-16">-->
<!--                    <label class="text-xs text-typo font-weight-bolder ms-1"-->
<!--                      >Description</label-->
<!--                    >-->
<!--                    <p class="form-text text-muted text-xs ms-1 d-inline">-->
<!--                      (optional)-->
<!--                    </p>-->
<!--                    <html-editor></html-editor>-->
<!--                  </v-col>-->
<!--                  <v-col sm="6" cols="12">-->
<!--                    <label class="text-xs text-typo font-weight-bolder ms-1"-->
<!--                      >Category</label-->
<!--                    >-->
<!--                    <v-select-->
<!--                      :items="categories"-->
<!--                      value="Clothing"-->
<!--                      color="rgba(0,0,0,.6)"-->
<!--                      class="-->
<!--                        input-style-->
<!--                        font-size-input-->
<!--                        text-light-input-->
<!--                        placeholder-light-->
<!--                        border-radius-md-->
<!--                        select-style-->
<!--                        mt-2-->
<!--                        mb-0-->
<!--                      "-->
<!--                      outlined-->
<!--                      single-line-->
<!--                      height="38"-->
<!--                    >-->
<!--                    </v-select>-->
<!--                    <label class="text-xs text-typo font-weight-bolder ms-1"-->
<!--                      >Sizes</label-->
<!--                    >-->
<!--                    <v-select-->
<!--                      :items="sizes"-->
<!--                      value="Medium"-->
<!--                      color="rgba(0,0,0,.6)"-->
<!--                      class="-->
<!--                        input-style-->
<!--                        font-size-input-->
<!--                        text-light-input-->
<!--                        placeholder-light-->
<!--                        border-radius-md-->
<!--                        select-style-->
<!--                        mt-2-->
<!--                        mb-0-->
<!--                      "-->
<!--                      outlined-->
<!--                      single-line-->
<!--                      height="38"-->
<!--                    >-->
<!--                    </v-select>-->
<!--                  </v-col>-->
                </v-row>
              </div>
              <v-btn :ripple="true" :elevation="0"
                     class="font-weight-bold btn-default bg-gradient-default mt-6 mb-2" color="primary" @click="create()"
              >
                <router-link to="/esg/portfolio/123" class="text-decoration-none text-white">Create</router-link>
              </v-btn>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>

export default {
  components: {},
  data() {
    return {
      name: ''
    };
  },
};
</script>
