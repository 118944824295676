<template>
  <div>
    <v-snackbar v-for="toast of toasts" :timeout="5000" :value="true" absolute centered top tile color="green accent-3">
      <span v-html="toast"></span>
    </v-snackbar>

    <v-dialog v-if="allowCreate" v-model="showForm" width="500">
<!--      <template v-slot:activator="{on, attrs}">-->
<!--        <v-btn @click="showCreateForm(on, attrs)" color="green"-->
<!--               class="text-capitalize ms-auto btn-primary bg-gradient-primary text-sm px-3 mb-5 text-decoration-none"-->
<!--               style="margin-left: 2.625rem !important;"-->
<!--        >-->
<!--          Add company-->
<!--        </v-btn>-->
<!--      </template>-->

      <v-card>
        <v-card-title class="text-h5">
          <template v-if="!form.id">Add company</template>
          <template v-if="form.id">Edit company</template>
        </v-card-title>
        <v-divider class="mb-5"></v-divider>

        <div class="px-5">
          <label class="text-xs text-typo font-weight-bolder ms-1">Name</label>
          <v-text-field ref="companyName" id="companyName" v-model="form.company_name" hide-details outlined color="rgba(0,0,0,.6)" light placeholder="eg. BotX"
                        @keydown.enter="create()"
                        class="font-size-input placeholder-lighter border border-radius-md mt-2">
          </v-text-field>
        </div>

        <div class="px-5 mt-5">
          <label class="text-xs text-typo font-weight-bolder ms-1">SFDR</label>
          <v-text-field v-model="form.sfdr" hide-details outlined color="rgba(0,0,0,.6)" light placeholder="eg. Article 8"
                        @keydown.enter="create()"
                        class="font-size-input placeholder-lighter border border-radius-md mt-2">
          </v-text-field>
        </div>

          <div class="px-5 mt-5">
              <label class="text-xs text-typo font-weight-bolder ms-1">Country Code</label>
              <v-text-field v-model="form.country_code" hide-details outlined color="rgba(0,0,0,.6)" light placeholder="en-US"
                            @keydown.enter="create()"
                            class="font-size-input placeholder-lighter border border-radius-md mt-2">
              </v-text-field>
          </div>

        <div class="px-5 mt-5">
          <label class="text-xs text-typo font-weight-bolder ms-1">Fund</label>
          <v-text-field v-model="form.fund" hide-details outlined color="rgba(0,0,0,.6)" light placeholder="Define fund"
                        @keydown.enter="create()"
                        class="font-size-input placeholder-lighter border border-radius-md mt-2">
          </v-text-field>
        </div>

        <div class="px-5 mt-5">
          <label class="text-xs text-typo font-weight-bolder ms-1">Logo URL</label>
          <v-text-field v-model="form.logo_url" hide-details outlined color="rgba(0,0,0,.6)" light placeholder="https://www..."
                        class="font-size-input placeholder-lighter border border-radius-md mt-2">
          </v-text-field>
        </div>

        <v-divider class="mt-5"></v-divider>

        <v-card-actions class="pb-3">
          <v-spacer></v-spacer>
          <v-btn color="grey" class="text-capitalize" text @click="showForm = false">Cancel</v-btn>
          <v-btn color="green" class="text-capitalize" dark @click="form.id ? update() : create()">
            <template v-if="!form.id">Create</template>
            <template v-if="form.id">Update</template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card class="card-shadow border-radius-xl">
      <v-card-text class="px-0 py-0">
        <v-simple-table class="table border-radius-xl" fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th v-if="showLogo" class="text-right text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" style="width: 130px;">Logo</th>
                <th class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{{itemHeader || 'Company'}}</th>
                <th v-if="showFSDR" class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">SFDR</th>
<!--                <th class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Fund</th>-->
                <th class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">External (BBG) Data</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in companies" :key="item._id">
                <td v-if="showLogo" class="text-right" :class="{ 'border-bottom': i != companies.length - 1 }">
                  <v-list class="py-0 d-inline-block">
                    <v-list-item class="px-0 pt-1">
                      <router-link :to="'/app/portfolio/company/' + item._id" class="text-decoration-none">
<!--                      <span class="text-decoration-none">-->
                        <img v-if="item.logo_url" :src="item.logo_url" class="my-0" style="max-height: 40px; max-width: 100px;">
<!--                      </span>-->
                      </router-link>
                    </v-list-item>
                  </v-list>
                </td>
                <td :class="{ 'border-bottom': i != companies.length - 1 }">
                  <router-link :to="'/app/portfolio/company/' + item._id" class="d-flex align-center text-typo text-decoration-none">
<!--                  <span class="d-flex align-center text-typo text-decoration-none">-->
                    <img v-if="item.countryCode" :src="'https://countryflagsapi.com/png/' + item.countryCode" height="15" class="me-2">
                    <span class="paragraph-text">{{item.company_name}}</span>
                  </router-link>
                </td>
                <td class="text-h5" :class="{ 'border-bottom': i != companies.length - 1 }">
                    <div class="d-flex align-center">
                        <v-scroll-x-transition leave-absolute>
                            <v-progress-circular v-if="item.trendsPending" :size="20" indeterminate class="text-grey-l3"></v-progress-circular>
                        </v-scroll-x-transition>
                        <v-scroll-x-transition leave-absolute>
                            <div v-if="!item.trendsPending" class="d-flex align-center">
                                <v-tooltip v-for="t in item.trends" top color="#212529" :key="t.name">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="me-4" v-bind="attrs" v-on="on">
                                            <span v-bind:style="{color: t.color}" class="me-2 text-sm" v-html="t.icon"></span>
                                            <span class="table-text">{{ t.value }} <template v-if="t.value !== 'N/A'">%</template> </span>
                                        </div>
                                    </template>
                                    <span>{{t.name}}</span>
                                </v-tooltip>
                            </div>
                        </v-scroll-x-transition>
                    </div>
                </td>
                <td v-if="showFSDR" :class="{ 'border-bottom': i != companies.length - 1 }">{{item.sfdr}}</td>
<!--                <td :class="{ 'border-bottom': i != companies.length - 1 }">{{item.fund}}</td>-->

                <td :class="{ 'border-bottom': i != companies.length - 1 }" class="text-center py-5">
                  <div v-if="showMenuIconsInline" class="d-flex align-center">
                    <v-dialog transition="dialog-bottom-transition" max-width="1200">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" rounded color="white" large class="text--white px-7 text-capitalize font-weight-bold">
                          Details
                        </v-btn>
                      </template>
                      <template v-slot:default="dialog">
                        <v-card>
                          <v-btn text @click="dialog.value = false" style="position: absolute; top: .5rem; right: 0;">
                            <v-icon size="16">fa fa-times</v-icon>
                          </v-btn>
                          <div class="py-9 px-5">
                            <div class="text-center">
                              <h2 class="h3 mb-3">Please subscript to BotX ESG</h2>
                              <p class="text-typo text-sm">For access to all portfolio and features please subscript to BotX ESG</p>
                            </div>

                            <v-row class="justify-center d-flex">
                              <v-col lg="4" v-for="plan in plans" :key="plan.title">
                                <v-card class="card-shadow border-radius-xl">
                                  <div class="px-6 pt-6 pb-4 text-center">
                                    <v-btn elevation="0" small :ripple="false" height="30" color="#e9ecef"
                                           class="border-radius-xl font-weight-bolder px-3 py-3 badge-font-size ms-auto text-dark">
                                      {{plan.title}}
                                    </v-btn>

    <!--                                  <h1 class="text-h1 text-typo font-weight-bold mt-1">-->
    <!--                                    <small>$</small>{{ plan.price }}-->
    <!--                                  </h1>-->

                                    <v-list class="py-6 text-lg-start text-center" color="transparent">
                                      <v-list-item v-for="item in plan.features" :key="item.title" class="px-0 py-0">
                                        <v-list-item-avatar :size="24" color="#fff" class="my-0 me-4 shadow" :class="`bg-gradient-` + item.color">
                                          <v-icon size="9" class="text-white">{{ item.icon }}</v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content class="text-left">
                                          <v-list-item-title v-text="item.title" class="text-body-1 ls-0 text-muted" ></v-list-item-title></v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                    <v-btn elevation="0" :ripple="false" height="43"
                                           class="font-weight-bold btn-primary py-3 text-xs px-6 ls-0 w-100 border-radius-md"
                                           :class="`bg-gradient-` + plan.buttonColor"
                                           @click="plan.click()"
                                    >
                                      {{ plan.buttonText }}
                                      <v-icon size="12">fas fa-arrow-right ms-2</v-icon>
                                    </v-btn>
                                  </div>
                                </v-card>
                              </v-col>
                            </v-row>
                          </div>
                        </v-card>
                      </template>
                    </v-dialog>
                  </div>

                  <v-menu
                      v-if="!showMenuIconsInline"
                      transition="slide-y-transition"
                      offset-y
                      offset-x
                      min-width="300"
                      max-width="300"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon :ripple="false" class="text-body" v-bind="attrs" v-on="on" small>
                        <v-icon size="16">fa fa-ellipsis-h text-sm</v-icon>
                      </v-btn>
                    </template>

                    <v-list class="py-0">
                      <v-list-item class="pa-3 list-item-hover-active">
                        <v-list-item-content class="pa-0">
                          <v-list-item-title class="text-body-2 ls-0 text-typo font-weight-600 mb-0">
                            <router-link :to="'/app/portfolio/' + item._id">
                              <h6 class="text-sm font-weight-normal mb-1 text-typo">
                                <v-icon size="16" class="me-1">ni ni-chart-bar-32</v-icon> Analytics
                              </h6>
                            </router-link>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pa-3 list-item-hover-active">
                        <v-list-item-content class="pa-0">
                          <v-list-item-title @click="showUpdateForm(item)" class="text-body-2 ls-0 text-typo font-weight-600 mb-0">
                            <h6 class="text-sm font-weight-normal mb-1 text-typo">
                              <v-icon size="16" class="me-1">ni ni-settings-gear-65</v-icon> Edit
                            </h6>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pa-3 list-item-hover-active">
                        <v-list-item-content class="pa-0">
                          <v-dialog v-model="showConfirmRemove" persistent max-width="420">
                            <template v-slot:activator="{on, attrs}">
                              <v-list-item-title v-bind="attrs" v-on="on" class="text-body-2 ls-0 text-typo font-weight-600 mb-0">
                                <h6 class="text-sm font-weight-normal mb-1 text-danger">
                                  <v-icon size="16" class="text-danger me-1">ni ni-fat-remove</v-icon> Delete
                                </h6>
                              </v-list-item-title>
                            </template>
                            <v-card>
                              <v-card-title class="text-h5">Are you sure?</v-card-title>
                              <v-card-text>Are you sure to remove company <strong> {{item.company_name}}</strong>?</v-card-text>
                              <v-card-actions class="pb-3">
                                <v-spacer></v-spacer>
                                <v-btn color="grey" text @click="showConfirmRemove = false">No</v-btn>
                                <v-btn color="red darken-2" dark @click="remove(item)">Remove</v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
              <tr v-if="showMore">
                <td colspan="3" class="text-center">
                  <v-btn elevation="0" :ripple="false" height="43"
                         class="text-capitalize ms-auto btn-primary bg-gradient-primary text-sm px-3 mt-3 mb-3"
                  >
                    <router-link to="/app/portfolio" class="text-white text-decoration-none">Show all companies</router-link>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>

export default {
  name: 'companies-grid',
  props: {
    allowCreate: Boolean,
    initialCompanies: Array,
    count: Number,
    showMore: Boolean,
    showMenuIconsInline: Boolean,
    showFSDR: Boolean,
    itemHeader: String,
    showLogo: {
        type: Boolean,
        default(showLogo) {
            if (showLogo === undefined) {
                return true
            }
            return showLogo
        }
    },
  },

  computed: {},

  data() {
    return {
      form: {
        id: null,
        company_name: null,
        sfdr: null,
        fund: null,
        logo_url: null,
        country_code: null
      },
      showForm: false,
      showConfirmRemove: false,
      toasts: [],
      dropdown: [
        {
          icon: require('@/assets/img/icons/document.svg'),
          title: '<span class="font-weight-bold">New scan</span> for AXA</h6>',
          time: '13 minutes ago',
        }
      ],
      companies: [],
      users: [],
    };
  },

  created() {},

  watch: {
    async initialCompanies(newCompanies, oldCompanies) {
      this.companies = newCompanies
      this.fetchTrends('parallel')

    }
  },

  methods: {
    async fetchTrends(mode) {
        if (mode === 'parallel') {
            await Promise.all(this.companies.map(async company => {
                const {data: {trends}} = await this.$store.getters.request(`company/${company._id}/trend`)
                company.setTrends(trends)
            }))
        } else if (mode === 'serial') {
            for (const company of this.companies) {
                const {data: {trends}} = await this.$store.getters.request(`company/${company._id}/trend`)
                company.setTrends(trends)
            }
        }
    },

    async create() {
      try {
        const {company_name, sfdr, fund, logo_url, country_code} = this.form
        const company = {company_name, sfdr, fund_isin: fund, logo_url, country_code}
        await this.$store.getters.create('company', company)
        this.refresh()
        this.showForm = false
        this.toasts.push(`Company <strong>${company_name}</strong> was created`)
      } catch (e) {
        console.error('Cannot create company', e)
      }
    },

    async update() {
      try {
        const {company_name, sfdr, fund, logo_url} = this.form
        const company = {company_name, sfdr, fund, logo_url: logo_url || null}
        await this.$store.getters.update('company', this.form.id, company)
        this.refresh()
        this.showForm = false
        this.toasts.push(`Company <strong>${company_name}</strong> was updated`)
      } catch (e) {
        console.error('Cannot create company', e)
      }
    },

    async remove(i) {
      this.showConfirmRemove = false
      await this.$store.getters.delete('company', i.id)
      this.toasts.push(`Company <strong>${i.company_name}</strong> was removed`)
      this.refresh()
    },

    async refresh() {
      const {data: companies} = await this.$store.getters.find('company', 0, 1000, '"company_name" desc')
      this.$store.commit('setCompanies', companies)
    },

    showCreateForm() {
      this.form.id = null
      this.form.company_name = null
      this.form.sfdr = null
      this.form.fund = null
      this.form.logo_url = null
      this.showForm = true
      this.focusNameInput()
    },

    showUpdateForm(item) {
      this.form.id = item.id
      this.form.company_name = item.company_name
      this.form.sfdr = item.sfdr
      this.form.fund = item.fund
      this.form.logo_url = item.logo_url
      this.showForm = true
      this.focusNameInput()
    },

    focusNameInput() {
      setTimeout(() => this.$refs.companyName.$el.focus(), 400)
    },
  }
};
</script>

