<template>
    <div>
        <v-navigation-drawer v-model="specialDrawer"
                             fixed
                             temporary
                             right
                             :width="900"
                             :hide-overlay="true"
                             tag="div"
                             class="drawer--sticky"
        >
            <feed-card :item="specialCheck" :tab="'tab-articles'" @close="specialDrawer = false"></feed-card>
            <!--      <feed-check-articles :check="specialCheck" :bings="specialBings" @close="specialDrawer = false"></feed-check-articles>-->
        </v-navigation-drawer>

        <v-navigation-drawer :stateless="specialDrawer" v-model="articlesDrawer" fixed temporary right :width="900"
                             :hide-overlay="false" tag="div" class="drawer--sticky">
            <feed-card :item="articlesCompany" :tab="articlesTab" @close="articlesDrawer = false"
                       @showBings="showBingDrawer($event)"></feed-card>
        </v-navigation-drawer>

        <v-navigation-drawer v-model="bingDrawer"
                             :stateless="articlesDrawer"
                             fixed
                             right
                             temporary
                             :width="900"
                             :hide-overlay="true"
                             tag="div"
                             class="drawer--sticky"
        >
          <feed-check-articles
            :check="bingCheck"
            :bings="bingBings"
            @close="bingDrawer = false"
          ></feed-check-articles>
        </v-navigation-drawer>

        <v-container fluid class="px-6 py-6">
            <v-row v-if="fund && lastScan">
                <v-col cols="12" class="z-index-2">
                    <v-card class="bg-transparent mb-6 px-4 py-4 pl-0">
                        <h1 class="text-h1 text-typo font-weight-bolder mb-0 d-flex align-center">
                            <img v-if="lastScan.logo_url" :src="fund.logo_url" class="my-0 me-5"
                                 style="max-width: 150px; max-height: 70px;">
                            {{ fund.fund_name }}
                            <span v-if="fund.rank"
                                  class="font-weight-bolder text-h3 text-typo mb-0">{{ lastScan.rank }}%</span>
                        </h1>
                    </v-card>
                    <v-row>
                        <v-col sm="12" md="4">
                            <detail-fund-attribute label="ISIN" icon="fa fa-hashtag">
                                <small v-if="fund.isin" class="text-body">{{ fund.isin }}</small>
                            </detail-fund-attribute>

                            <detail-fund-attribute label="SFDR" icon="fa fa-signal" class="mt-4">
                                <small v-if="lastScan.sfdr_class" class="text-body">{{ lastScan.sfdr_class }}</small>
                            </detail-fund-attribute>

                            <detail-fund-attribute label="FNG seal" class="mt-4">
                                <small v-if="lastScan.fng_seal" class="text-body">
                                    <v-icon v-if="lastScan.fng_seal === 'Yes'" class="text-green">fa fa-check</v-icon>
                                    <v-icon v-if="lastScan.fng_seal === 'No'" class="text-red">fa fa-times</v-icon>
                                    <template v-if="lastScan.fng_seal !== 'Yes' && lastScan.fng_seal !== 'No'">
                                        {{ lastScan.fng_seal.length ? lastScan.fng_seal : 'N/A' }}
                                    </template>
                                </small>
                            </detail-fund-attribute>

                            <v-card class="card-shadow border-radius-xl mt-4">
                                <div class="px-4 pt-4">
                                    <v-row>
                                        <v-col md="6">
                                            <h6 class="mb-0 text-h6 font-weight-bold text-typo">Last Scan:</h6>
                                        </v-col>
                                        <v-col md="6" class="text-end">
                                            <div v-for="i in fundScans" :key="i.isin">
                                                <v-icon size="14" class="text-typo">far fa-calendar-alt me-2</v-icon>
                                                <small class="text-body">{{ toDateTime(i.created_on) }}</small>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card>
                        </v-col>

                        <v-col sm="12" md="4">
                            <detail-fund-attribute label="Fund Details" icon="fa fa-info-circle">
                                <a
                                    v-if="lastScan.url_doc"
                                    :href="lastScan.url_doc"
                                    target="_blank"
                                >
                                    <small class="text-body">{{ lastScan.url_doc_preview }}</small>
                                    <v-icon class="ms-2 text-typo">fas fa-external-link-alt</v-icon>
                                </a>
                                <small v-if="!lastScan.url_doc" class="text-grey-l3">N/A</small>
                            </detail-fund-attribute>

                            <detail-fund-attribute label="Min. percent taxonomy" class="mt-4">
                                <small v-if="lastScan.min_perc_taxonomy_align"
                                       class="text-body">{{ lastScan.min_perc_taxonomy_align }}</small>
                                <small v-if="!lastScan.min_perc_taxonomy_align" class="text-grey-l3">N/A</small>
                            </detail-fund-attribute>

                            <detail-fund-attribute label="Min. share sustainable investment" class="mt-4">
                                <small v-if="lastScan.min_share_sustain_invest"
                                       class="text-body">{{ lastScan.min_share_sustain_invest }}</small>
                                <small v-if="!lastScan.min_share_sustain_invest" class="text-grey-l3">N/A</small>
                            </detail-fund-attribute>

                            <detail-fund-attribute label="PAI" class="mt-4">
                                <small v-if="lastScan.pai" class="text-body">{{ lastScan.pai }}</small>
                                <small v-if="!lastScan.pai" class="text-grey-l3">N/A</small>
                            </detail-fund-attribute>

                            <detail-fund-attribute v-if="lastScan.additional" label="Additional" class="mt-4">
                                <small v-if="lastScan.additional" class="text-body">{{ lastScan.additional }}</small>
                                <small v-if="!lastScan.additional" class="text-grey-l3">N/A</small>
                            </detail-fund-attribute>
                        </v-col>

                        <v-col sm="12" md="4">
                            <detail-fund-attribute label="Contact" icon="fa fa-envelope">
                                <a v-if="lastScan.contact_email" class="text-sm text-typo"
                                   :href="'mailto:' + lastScan.contact_email">{{ lastScan.contact_email }}</a>
                                <small v-if="!lastScan.contact_email" class="text-grey-l3">N/A</small>
                            </detail-fund-attribute>

                            <detail-fund-attribute label="Website" icon="fa fa-globe" class="mt-4">
                                <a
                                    v-if="lastScan.url_domain"
                                    :href="'http://' + lastScan.url_domain"
                                    target="_blank"
                                >
                                    <small class="text-body">{{ lastScan.url_domain }}</small>
                                    <v-icon class="ms-2 text-typo">fas fa-external-link-alt</v-icon>
                                </a>
                            </detail-fund-attribute>
                        </v-col>
                    </v-row>

                    <v-row>


                    </v-row>

                    <!--          <v-row>-->
                    <!--            <v-col cols="12">-->
                    <!--              <v-row>-->
                    <!--                <v-col v-for="t in fund.trends" sm="4" cols="12">-->
                    <!--                  <v-card class="border-radius-xl overflow-hidden card-shadow">-->
                    <!--                    <div class="px-4 pb-0 pt-4">-->
                    <!--                      <p class="text-sm mb-0 text-capitalize font-weight-bold text-body">{{t.name}}</p>-->
                    <!--                      <h5 class="font-weight-bolder text-h5 text-typo mb-0">{{t.value}}% <span class="text-success text-sm font-weight-bolder">+{{t.diff}}%</span></h5>-->
                    <!--                    </div>-->
                    <!--                    <div>-->
                    <!--                      <esg-line-chart :id="t.name" :datasets="t.datasets" :height="200"></esg-line-chart>-->
                    <!--                    </div>-->
                    <!--                  </v-card>-->
                    <!--                </v-col>-->
                    <!--              </v-row>-->
                    <!--            </v-col>-->
                    <!--          </v-row>-->

                    <v-row>


                        <v-col sm="12" md="12">
                            <v-card class="card-shadow border-radius-xl">
                                <div class="px-4 pt-4 mb-4">
                                    <v-row>
                                        <v-col md="6">
                                            <h6 class="mb-0 text-h6 font-weight-bold text-stypo">Companies in Fund</h6>
                                        </v-col>
                                        <v-col md="6" class="text-end">
                                            <span v-if="hasWarnings" class="text-red text-sm">Found warnings</span>
                                            <v-icon v-if="hasWarnings" class="ms-2 text-red">fas
                                                fa-exclamation-triangle
                                            </v-icon>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="px-4 pb-4 pt-2">
                                    <v-scroll-x-transition leave-absolute>
                                        <div v-if="!companies.length && !companiesPending"
                                             class="text-grey-l3 text-left text-sm">No companies found
                                        </div>
                                    </v-scroll-x-transition>

                                    <v-scroll-x-transition leave-absolute>
                                        <div v-if="companies.length && !companiesPending">
                                            <div v-for="(i, index) in companies" :key="i.company_name">
                                                <v-list-item class="px-0 py-2">
                                                    <v-list-item-content class="py-0">
                                                        <div class="d-flex flex-column">
                                                            <h6 class="mb-1 text-typo text-sm font-weight-bold d-flex justify-lg-space-between w-100">
                                                                <router-link :to="'/app/portfolio/company/' + i._id"
                                                                             class="text-typo text-decoration-none me-2">
                                                                    {{ i.company_name }}
                                                                </router-link>
                                                                <v-scroll-x-transition leave-absolute>
                                                                    <v-progress-circular v-if="i.checkPending"
                                                                                         :size="20" indeterminate
                                                                                         class="text-grey-l3"></v-progress-circular>
                                                                </v-scroll-x-transition>
                                                                <div v-if="i.plausibilityChecks">
                                                                    <div v-if="i.plausibilityChecks.length">
                                                                        <div v-for="check in i.plausibilityChecks">
                                                                            <v-scroll-x-transition leave-absolute>
                                                                                <v-progress-circular
                                                                                    v-if="companiesWithWarningsPending"
                                                                                    :size="20" indeterminate
                                                                                    class="text-grey-l3"></v-progress-circular>
                                                                            </v-scroll-x-transition>
                                                                            <v-scroll-x-transition leave-absolute>
                                                                                <a
                                                                                    v-if="!companiesWithWarningsPending && i.warningsLength"
                                                                                    @click="showChecks(i, check)"
                                                                                    class="text-red text-decoration-underline"
                                                                                >Show <span
                                                                                    class="font-weight-black">{{
                                                                                        i.warningsLength
                                                                                    }}</span>
                                                                                    warnings</a>
                                                                            </v-scroll-x-transition>
                                                                            <v-scroll-x-transition leave-absolute>
                                                                                <v-icon
                                                                                    v-if="!companiesWithWarningsPending && !i.warningsLength"
                                                                                    class="text-green">fas fa-check
                                                                                </v-icon>
                                                                            </v-scroll-x-transition>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        v-if="!i.plausibilityChecks.length && !i.checkPending"
                                                                        class="text-grey-l3">N/A
                                                                    </div>
                                                                </div>
                                                            </h6>
                                                        </div>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <hr class="horizontal"
                                                    :class="{ dark: index != companies.length - 1 }"/>
                                            </div>
                                        </div>
                                    </v-scroll-x-transition>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <canvas id="grhelper"></canvas>
    </div>
</template>

<script>
import {faker} from '@faker-js/faker'
import ESGLineChart from '@/views/Widgets/Charts/LineChart'
import DetailFundAttribute from './components/DetailFundAttribute'
import {
    ENVIRONMENT_COLOR,
    ENVIRONMENT_GRADIENT,
    getTooltipPercentFormatted, GOVERNANCE_COLOR,
    GOVERNANCE_GRADIENT, SOCIAL_COLOR,
    SOCIAL_GRADIENT
} from '@/util/charts'
import {FundScan} from '@/entities/FundScan'
import {CheckFactory} from '@/entities/CheckFactory'
import FeedCheckArticles from '@/views/Pages/Feed/FeedCheckArticles'
import FeedCard from '@/views/Pages/Feed/FeedCard'
import moment from 'moment'
import {MIN_SENTIMENT_SCORE} from '@/util/min-sentiment-score'
import {sortBy, chunk} from 'lodash'

export default {
    name: 'Fund',
    components: {
        'esg-line-chart': ESGLineChart,
        DetailFundAttribute,
        FeedCheckArticles,
        FeedCard,
    },

    data: function () {
        return {
            fund: null,
            fundScans: [],
            lastScan: null,
            companies: [],
            hasWarnings: false,
            companiesPending: false,
            companiesWithWarningsPending: false,
            trends: [],

            articlesDrawer: null,
            articlesCompany: null,
            articlesTab: 'tab-summary',

            specialDrawer: null,
            specialCheck: null,
            specialBings: [],

            bingDrawer: null,
            bingCheck: null,
            bingBings: []
        }
    },

    async created() {
        const [fundResponse, countries] = await Promise.all([
            this.$store.state.api.get(`/fund/scans/${this.$route.params.id}`),
            CheckFactory.fetchCountries()
        ])

        const {data: {scan, companies}} = fundResponse

        this.fund = scan
        let scans = [this.fund]

        this.fundScans = scans.map(i => new FundScan(i))
        if (scans?.length) {
            this.lastScan = this.fundScans[0]
        }

        this.companiesPending = true

        this.companies = companies.map(i => {
            return {...i, checkPending: true}
        })

        this.companiesPending = false

        const companyChunks = chunk(this.companies.map(i => i._id), 6)

        for (const companyIds of companyChunks) {
            const companiesWithChunks = await this.$store.getters.request(
                `company/check/bulk`,
                'POST',
                {companyIds}
            )
            companiesWithChunks.data.map(async ({company, checks}) => {
                const i = this.companies.find(c => c._id === company._id)
                i.checks = checks
                i.plausibilityChecks = []
                if (i.company_name === '5 Cablevision Lightpath LLC') {
                    console.log(i)
                    for (const c of i.checks) {
                        for (const b of c.bings) {
                            console.log(b.res_json)
                            if (b.res_json < MIN_SENTIMENT_SCORE) {
                                console.log('WARNING', b)
                            }
                        }
                    }
                }

                i.warningsLength = i.checks.filter(i => i.min_sentiment_score < MIN_SENTIMENT_SCORE).length
                if (i.checks?.length) {
                    i.plausibilityChecks.push(await CheckFactory.createPlausibilityCheck(i, countries))
                }
                i.checkPending = false
            })
        }

        // await Promise.all(this.companies.map(async (i, index) => {
        //     if (index === 0) {
        //         const {data: {checks}} = await this.$store.getters.request(`company/${i._id}/check`)
        //         i.checks = checks
        //         i.plausibilityChecks = []
        //         i.warningsLength = i.checks.filter(i => i.min_sentiment_score < MIN_SENTIMENT_SCORE).length
        //         if (i.checks?.length) {
        //             i.plausibilityChecks.push(await CheckFactory.createPlausibilityCheck(i, countries))
        //         }
        //         i.checkPending = false
        //     }
        // }))
        //
        // await Promise.all(this.companies.map(async (i, index) => {
        //     if (index > 0) {
        //         const {data: {checks}} = await this.$store.getters.request(`company/${i._id}/check`)
        //         i.checks = checks
        //         i.plausibilityChecks = []
        //         i.warningsLength = i.checks.filter(i => i.min_sentiment_score < MIN_SENTIMENT_SCORE).length
        //         if (i.checks?.length) {
        //             i.plausibilityChecks.push(await CheckFactory.createPlausibilityCheck(i, countries))
        //         }
        //         i.checkPending = false
        //     }
        // }))

        this.companies = sortBy(this.companies, i => i.warningsLength).reverse()
    },

    mounted() {
        this.generateTrends()
    },

    methods: {
        toDate(date) {
            return moment(date).format('ddd, D. MMMM, YYYY')
        },

        toDateTime(date) {
            return moment(date).format('ddd, D. MMMM, YYYY [at] HH:mm')
        },

        generateTrends() {
            this.trends = [
                {
                    name: 'Environment',
                    color: ENVIRONMENT_COLOR,
                    value: faker.datatype.number({min: 0, max: 100}),
                    diff: faker.datatype.number({min: 0, max: 20}),
                    datasets: [
                        {
                            label: 'Environment',
                            tension: 0.4,
                            borderWidth: 3,
                            pointRadius: 0,
                            borderColor: ENVIRONMENT_COLOR,
                            backgroundColor: ENVIRONMENT_GRADIENT(document.getElementById('grhelper').getContext('2d')),
                            fill: true,
                            data: [40, 48, 59, 62, 51, 48, 55, 51, 68],
                            tooltip: getTooltipPercentFormatted(),
                            maxBarThickness: 6,
                        }
                    ]
                },
                {
                    name: 'Social',
                    color: SOCIAL_COLOR,
                    value: faker.datatype.number({min: 0, max: 100}),
                    diff: faker.datatype.number({min: 0, max: 20}),
                    datasets: [
                        {
                            label: 'Social',
                            tension: 0.4,
                            borderWidth: 3,
                            pointRadius: 0,
                            borderColor: SOCIAL_COLOR,
                            backgroundColor: SOCIAL_GRADIENT(document.getElementById('grhelper').getContext('2d')),
                            fill: true,
                            data: [80, 85, 89, 87, 82, 85, 85, 85, 80],
                            tooltip: getTooltipPercentFormatted(),
                            maxBarThickness: 6,
                        }
                    ]
                },
                {
                    name: 'Governance',
                    color: GOVERNANCE_COLOR,
                    value: faker.datatype.number({min: 0, max: 100}),
                    diff: faker.datatype.number({min: 0, max: 20}),
                    datasets: [
                        {
                            label: 'Governance',
                            tension: 0.4,
                            borderWidth: 3,
                            pointRadius: 0,
                            borderColor: GOVERNANCE_COLOR,
                            backgroundColor: GOVERNANCE_GRADIENT(document.getElementById('grhelper').getContext('2d')),
                            fill: true,
                            data: [60, 60, 65, 70, 60, 65, 65, 70, 75],
                            tooltip: getTooltipPercentFormatted(),
                            maxBarThickness: 6,
                        }
                    ]
                },
            ]
        },

        showChecks(company, check) {
            this.articlesDrawer = !this.articlesDrawer
            console.log(check)
            this.articlesCompany = {company, check}
        },

        showBingDrawer(event) {
            this.bingDrawer = true
            this.bingCheck = event.check
            this.bingBings = event.bings
        }
    }
}
</script>
