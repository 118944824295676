<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col md="12" lg="10" class="z-index-2">
          <v-card class="bg-transparent mb-6 px-4 py-4 ps-0">
            <div class="d-flex align-center justify-space-between">
                <h2 class="text-h2 text-typo font-weight-bolder">Companies Management</h2>
                <v-btn @click="showCreateForm()" color="green"
                       class="text-capitalize ms-auto btn-primary bg-gradient-primary text-sm px-3 mb-5 text-decoration-none"
                       style="margin-left: 2.625rem !important;"
                >
                    <span>Add company</span>
                </v-btn>
            </div>
            <p class="text-hypo mt-2">For more advanced database management, such as imports, etc., please use BOTX web console application</p>
          </v-card>

          <v-card class="bg-transparent tabs">
            <v-row>
              <v-col cols="12" class="mt-5">
                <companies-grid :allow-create="true" ref="companiesGrid" :show-logo="false" :initial-companies="companies"></companies-grid>
              </v-col>
            </v-row>
          </v-card>

            <div class="text-center my-5">
              <v-btn @click="next()" color="green"
                     class="text-capitalize ms-auto btn-primary bg-gradient-primary text-sm px-3 mb-5 text-decoration-none"
                     style="margin-left: 2.625rem !important;"
              >
                <span>Load more</span>
              </v-btn>
            </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CompaniesGrid from '@/views/Widgets/CompaniesGrid'

export default {
  name: 'PortfolioCompanies',
  components: {
    CompaniesGrid,
  },

  computed: {
    companies() {
      return this.$store.state.companies
    },

    api() {
      return this.$store.state.api
    }
  },

  data: function () {
    return {
      company: null,
      showCreate: false,
      page: 0,
    }
  },

  async created() {
    this.fetch()
  },

  methods: {
    async fetch() {
      try {
          const {data: companies} = await this.$store.getters.request(`company?page=${this.page}&perPage=20`, 'GET')
          console.log(companies)
          this.$store.commit('setCompanies', companies.data)
      } catch (e) {
          console.error('Cannot fetch companies', e)
          this.$store.commit('setCompanies', [])
      }
    },

    next() {
        window.scrollTo(0, 0)
        ++this.page
        this.fetch()
    },

    showCreateForm() {
      this.$refs.companiesGrid.showCreateForm()
    }
  }
}
</script>
<style lang="scss"></style>
